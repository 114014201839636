import Image from 'next/image';
import React from 'react';
import $ from './image-block.module.scss';

interface Props {
  src: string;
  caption?: string;
  anchor?: string;
  height?: number;
  width?: number;
}

const ImageBlock: React.FC<Props> = ({
  src,
  caption,
  anchor,
  height,
  width,
}) => (
  <div id={anchor} className={$.container}>
    <Image src={src} alt={caption ?? src} height={height} width={width} />
  </div>
);

export default ImageBlock;
