import React from 'react';
import InnerHtml from '~source/ui/components/atoms/inner-html/inner-html';
import $ from './rich-content.module.scss';

interface Props {
  htmlString: string;
  anchor?: string;
}

const RichContent: React.FC<Props> = ({ htmlString, anchor }) => (
  <InnerHtml
    container={<div id={anchor} className={$.container} />}
    html={htmlString}
  />
);

export default RichContent;
