import cx from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { Banner } from '~source/core/models/components/molecules/banner';
import SmallProductBanner from '~source/ui/components/molecules/small-product-banner/small-product-banner';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import $ from './small-banners-swiper.module.scss';

interface Props {
  banners: Banner[];
}

const SmallBannersSwiper: React.FC<Props> = ({ banners }) => (
  <SwiperCarousel height={165} slidesPerView={1} nested className={$.margin}>
    {banners.map((banner) => (
      <SwiperSlide key={banner.id} className={cx($.width, $.height)}>
        <div className={$.height}>
          <SmallProductBanner
            {...banner}
            key={banner.id}
            className={$.height}
          />
        </div>
      </SwiperSlide>
    ))}
  </SwiperCarousel>
);

export default SmallBannersSwiper;
