import classnames from 'classnames';
import { Highlights as HighlightsModel } from '~source/core/models/components/molecules/highlights';
import Container from '~source/ui/components/containers/container/container';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import Banner from '~source/ui/components/molecules/banner/banner';
import SmallProductBanner from '~source/ui/components/molecules/small-product-banner/small-product-banner';
import SmallBannersSwiper from '~source/ui/components/organisms/small-banners-swiper/small-banners-swiper';
import Grid from '~source/ui/components/templates/grid/grid';
import $ from './highlights.module.scss';

export default function Highlights(props: HighlightsModel) {
  const { type, banners, title, featured } = props;
  const smallBannerClasses = classnames(
    $.smallBanners,
    type === 'Three-banners' && $.smallBannersThree,
    type === 'Two-banners' && $.smallBannersTwo,
    type === 'One-banner' && $.smallBannerOne,
    banners && banners?.length > 1 && $.smallBannersGap,
  );

  return (
    <Container>
      <section className={$.container}>
        <h2 className="visually-hidden">{title}</h2>
        <Grid className={$.grid}>
          {featured && (
            <GridItem xs={12} l={type !== 'No-banners' ? 8 : 12}>
              <div className={$.bigBanner}>
                <Banner {...featured} titleAtTheTop showText="tablet and up" />
              </div>
            </GridItem>
          )}
          {banners && type !== 'No-banners' ? (
            <GridItem xs={12} l={4} className={$.desktopBanners}>
              <div className={smallBannerClasses}>
                {banners.map((banner) => (
                  <SmallProductBanner {...banner} key={banner.id} />
                ))}
              </div>
            </GridItem>
          ) : null}
          {banners && type !== 'No-banners' ? (
            <div className={$.tabletBanners}>
              <SmallBannersSwiper banners={banners} />
            </div>
          ) : null}
        </Grid>
      </section>
    </Container>
  );
}
