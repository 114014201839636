import * as React from 'react';
import { SvgIcon } from '~source/core/models/components/atoms/svg-icon';
import $ from './icon-check.module.scss';

type Props = Omit<SvgIcon, 'theme' | 'className'> & {
  color: string;
};

export default function IconCheck({
  ariaHidden = true,
  color,
  width,
  height,
}: Props) {
  const style = {
    '--bg-color': color,
  } as React.CSSProperties;

  return (
    <svg
      className={$.wrapper}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      width={width}
      height={height}
      aria-hidden={ariaHidden}
      style={style}
    >
      <circle className={$.circle} cx="26" cy="26" r="25" fill="none" />
      <path className={$.check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  );
}
