import * as React from 'react';
import { BannerImage } from '~source/core/models/components/atoms/banner-image';
import { FullWidthBanner as Banner } from '~source/core/models/components/molecules/full-width-banner';
import ResponsiveImage from '~source/ui/components/atoms/responsive-image/responsive-image';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './full-width-banner.module.scss';

const createImageStyling = (image: BannerImage) => {
  const { width, height } = image.image || {};
  let style: React.CSSProperties = {};
  let imageClassName: string;

  if (!width || !height) return null;

  if (width > 1440) {
    imageClassName = $.imageContainerFullWidth;
    style = { height };
  } else {
    imageClassName = $.imageContainerStatic;
    style = { aspectRatio: width / height };
  }

  return { imageClassName, style };
};

const FullWidthBanner: React.FC<Banner> = ({ children, images, className }) => {
  return (
    <section className={cx($.container, className)}>
      <div className={$.backgroundImageContainer}>
        {images.map((image) => {
          return (
            <ResponsiveImage
              key={`${image.image?.id}-${image.device}`}
              image={image}
              sizes="100vw"
            />
          );
        })}
      </div>

      {images.map((image) => {
        if (!image.image) return null;
        const styling = createImageStyling(image);
        if (!styling) return null;
        const { imageClassName, style } = styling;

        return (
          <ResponsiveImage
            key={`${image.image.id}-${image.device}`}
            image={image}
            className={cx($.imageContainer, imageClassName)}
            style={style}
            sizes="
              (max-width: 640px) 640px,
              (max-width: 750px) 750px,
              (max-width: 828px) 828px,
              (max-width: 1080px) 1080px,
              (max-width: 1200px) 1200px,
              (max-width: 1500px) 1500px,
              1920px"
          />
        );
      })}

      {children}
    </section>
  );
};

export default FullWidthBanner;
