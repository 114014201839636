import * as React from 'react';
import { LoginButton as Props } from '~source/core/models/components/atoms/login-button';
import Button from '~source/ui/components/atoms/buttons/button/button';
import { useAccount } from '~source/ui/hooks/auth/useAccount/useAccount';
import $ from './login-button.module.scss';

const LoginButton: React.FC<Props> = ({ label }) => {
  const { signIn } = useAccount();

  return (
    <Button variant="red" onClick={signIn} className={$.wrapper}>
      {label}
    </Button>
  );
};

export default LoginButton;
