import { useEffect } from 'react';
import { ProductsList } from '~source/core/models/components/organisms/products-list';
import { sendAnalyticsOfItemList } from '~source/core/services/e-commerce/e-commerce';
import Container from '~source/ui/components/containers/container/container';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import ProductBox from '~source/ui/components/molecules/product-box/product-box';
import Grid from '~source/ui/components/templates/grid/grid';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './products-mini-grid.module.scss';

type GridItemSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props extends ProductsList {
  nested?: boolean;
}

export default function ProductsMiniGrid({ title, products, nested }: Props) {
  const list = products.slice(0, 4);
  const size = Math.floor(12 / list.length) as GridItemSize;

  useEffect(() => {
    if (list.length > 0)
      sendAnalyticsOfItemList({
        products: list,
        eventType: 'view_item_list',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={cx($.container, nested && $.containerNested)}>
      <h2 className={$.title}>{title}</h2>
      <Grid>
        {list.map((product, index) => (
          <GridItem l={size} m={6} key={product.productId}>
            <ProductBox product={product} index={index} />
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
}
