import * as React from 'react';
import { PlayerBanner as Banner } from '~source/core/models/components/organisms/player-banner';
import Button from '~source/ui/components/atoms/buttons/button/button';
import InnerHtml from '~source/ui/components/atoms/inner-html/inner-html';
import Translate from '~source/ui/components/atoms/translate/translate';
import FullWidthBanner from '~source/ui/components/molecules/full-width-banner/full-width-banner';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { getTitleClassName } from '~source/ui/utils/get-title-classname';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './player-banner.module.scss';

const PlayerBanner: React.FC<Banner> = ({
  images,
  links,
  titleSize,
  titleFont,
  titleFontColor,
  playerName,
  jerseyNumber,
  description,
  playerStatistics,
}) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const detailsRef = React.useRef<HTMLDetailsElement>(null);

  const numberClassName = cx(
    $.number,
    titleFontColor === 'Black' && $.numberDark,
  );

  const titleClassName = cx(
    $.title,
    titleFontColor === 'Black' && $.titleDark,
    titleFont === 'lexia' && $.titleLexia,
    getTitleClassName(titleSize, $),
  );

  const descriptionClassName = cx(
    $.description,
    titleFont === 'lexia' && $.descriptionLexia,
  );

  const statisticsClassName = cx(
    $.statistics,
    titleFont === 'lexia' && $.statisticsLexia,
  );

  const isContent = playerStatistics || description;
  const firstName = playerName.split(' ')[0];

  const handleDetailsKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  };

  React.useEffect(() => {
    const { current: element } = detailsRef;

    if (!element) return undefined;

    const handleToggle = () => setIsOpen(element.open);

    element.addEventListener('toggle', handleToggle);

    return () => {
      element.removeEventListener('toggle', handleToggle);
    };
  }, []);

  return (
    <>
      <FullWidthBanner
        images={images}
        className={cx($.container, $.maxHeight, !isContent && $.marginBottom)}
      >
        <div className={$.contentWrapper}>
          <div className={$.content}>
            {jerseyNumber && playerName && (
              <div className={$.block}>
                <div className={numberClassName}>{jerseyNumber}</div>
                <h2 className={titleClassName}>{playerName}</h2>
              </div>
            )}

            {description && (
              <p
                className={cx(
                  descriptionClassName,
                  titleFontColor === 'Black' && $.descriptionDark,
                  $.desktop,
                )}
              >
                {description}
              </p>
            )}

            {playerStatistics && (
              <InnerHtml
                container={
                  <div
                    className={cx(
                      statisticsClassName,
                      titleFontColor === 'Black' && $.descriptionDark,
                      $.desktop,
                    )}
                  />
                }
                html={playerStatistics}
              />
            )}

            {links.map(({ link, color }) => (
              <Button
                key={link.id}
                className={$.button}
                type="link"
                label={link.name}
                variant={color}
                link={link.url}
              >
                {link.name}
              </Button>
            ))}
          </div>
        </div>
      </FullWidthBanner>
      {isContent && (
        <details
          ref={detailsRef}
          className={$.details}
          onKeyUp={(e) => handleDetailsKeyUp(e)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          aria-label={isOpen ? t('CLOSE') : t('OPEN')}
          aria-expanded={isOpen}
          aria-controls="player-banner-content"
        >
          <summary id="player-banner-summary" className={$.summary}>
            <strong className={$.summaryTitle}>
              <Translate code="PLAYER_MORE_INFO" playerName={firstName} />
            </strong>

            <img
              src="/shop/images/icons/ic-chevron-left.svg"
              className={cx($.chevron, { [$.isOpen]: isOpen })}
              alt=""
              width="20"
              height="20"
            />
          </summary>

          <div id="player-banner-content" className={$.info}>
            {description && (
              <p className={descriptionClassName}>{description}</p>
            )}

            {playerStatistics && (
              <InnerHtml
                container={<div className={statisticsClassName} />}
                html={playerStatistics}
              />
            )}

            <hr className={$.divider} />
          </div>
        </details>
      )}
    </>
  );
};

export default PlayerBanner;
