import cx from 'classnames';
import Image from 'next/image';
import React from 'react';
import { Banner } from '~source/core/models/components/molecules/banner';
import Button from '~source/ui/components/atoms/buttons/button/button';
import getBannerImageClassName from '~source/ui/utils/getters/get-banner-image-classname';
import $ from './small-product-banner.module.scss';

interface Props extends Banner {
  className?: string;
}

export default function SmallProductBanner(props: Props) {
  const { isDark, links, title, images, className, text } = props;

  const wrapClasses = cx(className, $.wrap, isDark && $.wrapIsDark);
  const firstLinkUrl = links[0] || null;
  const titleId = React.useId();

  return (
    <article
      className={wrapClasses}
      aria-labelledby={title ? titleId : undefined}
    >
      <div className={$.imagesContainer}>
        {images.map((img) => {
          const { image, device } = img;
          return (
            <div
              key={`${image?.id}-${device}`}
              className={cx($.imageWrap, $[getBannerImageClassName(device)])}
            >
              {image?.url && (
                <Image
                  className={$.image}
                  src={image.url}
                  alt={image.alt}
                  fill
                  sizes="(min-width: 90em) 392px, (min-width: 56em) 27vw, 100vw"
                />
              )}
            </div>
          );
        })}
      </div>

      <div className={$.content}>
        <div className={$.titles}>
          {title && (
            <h3 className={$.title} id={titleId}>
              {title}
            </h3>
          )}
          {text && <p className={$.text}>{text}</p>}
        </div>
        {firstLinkUrl && (
          <Button
            className={$.link}
            label={firstLinkUrl.link.name}
            link={firstLinkUrl.link.url}
            variant={firstLinkUrl.color || undefined}
            type="link"
            key={firstLinkUrl.link.id}
          />
        )}
      </div>
    </article>
  );
}
