import * as React from 'react';
import {
  SelectedFilterOptions,
  SetFilterFunction,
  SortDirection,
  SetSortDirectionFunction,
} from '~source/core/models/components/molecules/filters';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { PageContext } from '~source/core/models/components/templates/page';
import { ModuleType } from '~source/core/models/unions/module-type';
import CodeBlock from '~source/ui/components/atoms/code-block/code-block';
import HorizontalDivider from '~source/ui/components/atoms/horizontal-divider/horizontal-divider';
import ImageBlock from '~source/ui/components/atoms/image-block/image-block';
import LoginButton from '~source/ui/components/atoms/login-button/login-button';
import RichContent from '~source/ui/components/atoms/rich-content/rich-content';
import { ErrorCatcher } from '~source/ui/components/containers/error-catcher/error-catcher';
import BannersSwiper from '~source/ui/components/molecules/banners-swiper/banners-swiper';
import ButtonBlock from '~source/ui/components/molecules/button-block/button-block';
import FilterBar from '~source/ui/components/molecules/filter-bar/filter-bar';
import ProductHeader from '~source/ui/components/molecules/product-header/ProductHeader';
import TextBlock from '~source/ui/components/molecules/text-block/text-block';
import WelcomeText from '~source/ui/components/molecules/welcome-text/welcome-text';
import BannersSlider from '~source/ui/components/organisms/banners-slider/banners-slider';
import CategoriesSlider from '~source/ui/components/organisms/categories-slider/categories-slider';
import Highlights from '~source/ui/components/organisms/highlights/highlights';
import PlayerBanner from '~source/ui/components/organisms/player-banner/player-banner';
import ProductList from '~source/ui/components/organisms/product-list/product-list';
import ProductRecommendationsSlider from '~source/ui/components/organisms/product-recommendations-slider/product-recommendations-slider';
import ProductsMiniGrid from '~source/ui/components/organisms/products-mini-grid/products-mini-grid';
import ProductsSwiper from '~source/ui/components/organisms/products-swiper/products-swiper';
import DynamicShirtConfigurator from '~source/ui/components/organisms/shirt-configurator/dynamic-shirt-configurator';
import TakeoverBanner from '~source/ui/components/organisms/takeover-banner/takeover-banner';
import DynamicVideoPlayer from '~source/ui/components/organisms/video-player/dynamic-video-player';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';

export interface ProductOptions {
  products?: ProductTeaser[];
  totalProducts: number;
  currentPage: number;
  selectedProductFilterOptions?: SelectedFilterOptions;
  setProductFilterOption?: SetFilterFunction;
  selectedSortDirection?: SortDirection;
  setSortDirection?: SetSortDirectionFunction;
  totalPages: number;
  playerContext?: PageContext['player'];
}

interface SingleModuleProps {
  module: ModuleType;
  productOptions?: ProductOptions;
  firstModule: ModuleType;
}

const SingleModule = ({
  module,
  productOptions,
  firstModule,
}: SingleModuleProps) => {
  const { isSmallTablet } = useBreakpoints();

  switch (module.moduleType) {
    case 'highlights':
      return <Highlights {...module} />;
    case 'productsMiniGrid':
      // @TODO(refactor): Change ProductsMiniGrid to ProductsList, it's the same component.
      return <ProductsMiniGrid {...module} />;
    case 'productsCarousel':
      return <ProductsSwiper {...module} />;
    case 'bannersCarousel':
      return <BannersSwiper {...module} />;
    case 'shirtConfigurator':
      return <DynamicShirtConfigurator {...module} />;
    case 'productGrid':
      return <ProductList {...productOptions} {...module} />;
    case 'productHeader': {
      const isFirstModule = firstModule === module;

      return <ProductHeader {...module} isFirstModule={isFirstModule} />;
    }
    case 'imageBlock':
      return <ImageBlock {...module} />;
    case 'buttonBlock':
      return <ButtonBlock buttons={module.buttons} />;
    case 'codeBlock':
      return <CodeBlock {...module} />;
    case 'richContent':
      return <RichContent {...module} />;
    case 'textBlock':
      return <TextBlock {...module} />;
    case 'videoPlayer': {
      const {
        videoModes: { portrait, landscape },
        buttonLabel,
        buttonLink,
      } = module;
      const isPortrait = !!portrait.url && !isSmallTablet;
      const videoMode = isPortrait ? portrait : landscape;

      return (
        <DynamicVideoPlayer
          buttonLabel={buttonLabel}
          buttonLink={buttonLink}
          isPortrait={isPortrait}
          {...videoMode}
        />
      );
    }
    case 'filterBar': {
      if (!productOptions) return null;
      const {
        setProductFilterOption,
        selectedProductFilterOptions,
        selectedSortDirection,
        setSortDirection,
      } = productOptions;
      if (
        !setProductFilterOption ||
        !selectedProductFilterOptions ||
        !selectedSortDirection ||
        !setSortDirection
      )
        return null;

      return (
        <FilterBar
          {...module}
          setFilterOption={setProductFilterOption}
          selectedFilterOptions={selectedProductFilterOptions}
          selectedSortDirection={selectedSortDirection}
          setSortDirection={setSortDirection}
        />
      );
    }
    case 'welecomeText':
      return <WelcomeText {...module} />;
    case 'horizontalDivider':
      return <HorizontalDivider />;
    case 'playerBanner':
      return <PlayerBanner {...module} />;
    case 'takeoverBanner':
      return <TakeoverBanner {...module} />;
    case 'bannersSlider':
      return <BannersSlider {...module} />;
    case 'categoriesSlider':
      return <CategoriesSlider {...module} />;
    case 'productRecommendationsSlider':
      return <ProductRecommendationsSlider {...module} />;
    case 'loginButton':
      return <LoginButton {...module} />;
    default:
      return null;
  }
};

interface Props {
  modules: ModuleType[];
  productOptions?: ProductOptions;
}

const ModuleHandler: React.FunctionComponent<Props> = ({
  modules,
  productOptions,
}) => (
  <>
    {modules.map((module) => {
      const firstModule = modules[0];

      return (
        <ErrorCatcher key={module.id} title={module.moduleType}>
          <SingleModule
            module={module}
            productOptions={productOptions}
            firstModule={firstModule}
          />
        </ErrorCatcher>
      );
    })}
  </>
);

export default ModuleHandler;
