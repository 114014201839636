import * as React from 'react';
import type { TakeoverBanner as BannerProps } from '~source/core/models/components/organisms/takeover-banner';
import Button from '~source/ui/components/atoms/buttons/button/button';
import FullWidthBanner from '~source/ui/components/molecules/full-width-banner/full-width-banner';
import { getTitleClassName } from '~source/ui/utils/get-title-classname';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './takeover-banner.module.scss';

const TakeoverBanner: React.FC<BannerProps> = ({
  images,
  links,
  title,
  titleFont,
  titleFontColor,
  titleSize,
  contentAlignment,
  text,
}) => {
  const titleClassName = cx(
    $.title,
    titleFontColor === 'Black' && $.titleDark,
    titleFont === 'lexia' && $.titleLexia,
    getTitleClassName(titleSize, $),
  );

  const textClassName = cx(
    $.text,
    titleFontColor === 'Black' && $.textDark,
    titleFont === 'lexia' && $.textLexia,
  );

  return (
    <FullWidthBanner images={images} className={$.container}>
      <div className={$.contentWrapper}>
        <div
          className={cx(
            $.content,
            contentAlignment === 'Right' && $.isAlignedToRight,
          )}
        >
          {title && <h2 className={titleClassName}>{title}</h2>}

          {text && <p className={textClassName}>{text}</p>}

          {links.map(({ link, color }) => (
            <Button
              key={link.id}
              className={$.button}
              type="link"
              variant={color}
              link={link.url}
            >
              {link.name}
            </Button>
          ))}
        </div>
      </div>
    </FullWidthBanner>
  );
};

export default TakeoverBanner;
