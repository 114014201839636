import React from 'react';
import IconCheck from '~source/ui/components/atoms//icon/icon-check/icon-check';
import Button from '~source/ui/components/atoms/buttons/button/button';
import useAddedMessage from '~source/ui/components/organisms/product-detail-components/hero-block/hooks/useAddedMessage';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './product-recommendations-item.module.scss';

const ICON_COLOR = '#3db54f';
const ICON_SIZE = 30;

export const ProductRecommendationAddToCart = ({
  className,
  isProductPending,
  status,
  onAdd,
  onSizeSelection,
}: {
  className?: string;
  isProductPending: boolean;
  status: 'idle' | 'loading' | 'success' | 'error';
  onAdd: () => void;
  onSizeSelection?: (size: number | null) => void;
}) => {
  const t = useTranslate();
  const { isAddedMessageVisible, showAddedMessage } = useAddedMessage();

  React.useEffect(() => {
    if (status === 'success') {
      showAddedMessage();
      onSizeSelection?.(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const isAddingToBasket = status === 'loading';

  if (isAddedMessageVisible) {
    return (
      <div className={$.success}>
        <IconCheck color={ICON_COLOR} width={ICON_SIZE} height={ICON_SIZE} />
      </div>
    );
  }

  return (
    <Button
      type="button"
      variant="red"
      fontSize="extra-small"
      className={className}
      onClick={onAdd}
      disabled={isAddingToBasket || isProductPending}
      ariaLabelledby={
        isAddingToBasket ? 'button-adding-to-cart' : 'button-add-to-cart'
      }
    >
      {isAddingToBasket ? (
        <>
          <span id="button-adding-to-cart" className="visually-hidden">
            {t('ADDING_TO_BASKET')}
          </span>
          <span className={$.spinner} />
        </>
      ) : (
        <>
          <span id="button-add-to-cart" className="visually-hidden">
            {t('ADDED_TO_BASKET')}
          </span>
          <span className={cx($.icon, $.iconCart)} />
        </>
      )}
    </Button>
  );
};
