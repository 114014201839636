import React, { useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { HeaderBanner } from '~source/core/models/components/molecules/header-banner';
import ResponsiveImage from '~source/ui/components/atoms/responsive-image/responsive-image';
import FullWidthBanner from '~source/ui/components/molecules/full-width-banner/full-width-banner';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import { cx } from '~source/ui/utils/join-classnames';
import Content from './banner-content';
import $ from './banners-slider.module.scss';

type Props = {
  banners: HeaderBanner[];
};

const HEIGHT = 580;

const BannersSlider: React.FC<Props> = ({ banners }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  if (banners.length === 1) {
    return (
      <FullWidthBanner
        images={banners[0].images}
        className={cx($.container, $.maxHeight)}
      >
        <Content banner={banners[0]} />
      </FullWidthBanner>
    );
  }

  return (
    <SwiperCarousel
      ref={containerRef}
      className={$.container}
      height={HEIGHT}
      isFullWidth
      spaceBetween={0}
      isNavigation
      isLoop
      isScrollable
      isPagination={false}
    >
      {banners.map((banner) => {
        const { id, images } = banner;
        return (
          <SwiperSlide style={{ height: HEIGHT }} key={id}>
            <article
              style={{
                backgroundImage:
                  'url("/shop/images/backgrounds/visuals-patterns-red.webp")',
              }}
              className={$.wrapper}
            >
              <div className={$.backgroundImageContainer}>
                {images.map((image) => {
                  return (
                    <ResponsiveImage
                      key={`${image.image?.id}-${image.device}`}
                      image={image}
                      sizes="100vw"
                    />
                  );
                })}
              </div>

              <div className={$.imagesContainer}>
                {images.length > 0 &&
                  images.map((image) => (
                    <ResponsiveImage
                      key={`${image.image?.id}-${image.device}`}
                      image={image}
                      sizes="(min-width: 90em) 1240px, 100vw"
                    />
                  ))}
              </div>

              <Content banner={banner} />
            </article>
          </SwiperSlide>
        );
      })}
    </SwiperCarousel>
  );
};

export default BannersSlider;
