import { TitleSize } from '~source/core/models/components/molecules/banner';

export function getTitleClassName(
  titleSize: TitleSize | undefined,
  styles: Record<string, string>,
) {
  if (titleSize === 'mega-carousel') return styles.titleMegaCarousel;
  if (titleSize === 'mega') return styles.titleMega;
  if (titleSize === 'big') return styles.titleBig;
  if (titleSize === 'medium') return styles.titleMedium;
  if (titleSize === 'small') return styles.titleSmall;
  return undefined;
}
