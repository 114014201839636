import cx from 'classnames';
import dynamic from 'next/dynamic';
import SkeletonBox from '~source/ui/components/atoms/skeleton-box/skeleton-box';
import Container from '~source/ui/components/containers/container/container';
import $ from './shirt-configurator.module.scss';

export function Skeleton() {
  return (
    <Container>
      <div className={cx($.container, $.loaderWrap)}>
        <div className={cx($.content, $.loaderContentColumn)}>
          <SkeletonBox width="70%" height={40} className={$.title} />
          <SkeletonBox width="30%" height={24} marginBottom={9} />
          <SkeletonBox width={77} height={31} />
        </div>
        <div className={$.canvasContainer} />
        <div className={$.form}>
          <div className={$.row}>
            <SkeletonBox height={32} marginBottom={12} />
            <SkeletonBox height={32} />
          </div>
          <SkeletonBox height={32} marginBottom={12} />
          <SkeletonBox height={32} marginBottom={12} />
          <SkeletonBox height={32} marginBottom={12} />
          <hr className={cx($.divider, $.isInvisible)} />
          <SkeletonBox height={44} />
        </div>
      </div>
    </Container>
  );
}

const DynamicShirtConfigurator = dynamic(() => import('./shirt-configurator'), {
  loading: () => <Skeleton />,
});

export default DynamicShirtConfigurator;
