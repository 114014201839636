import type { PropsWithChildren } from 'react';
import React from 'react';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import ErrorMessage from '~source/ui/components/atoms/error-message/error-message';
import SkeletonBox from '~source/ui/components/atoms/skeleton-box/skeleton-box';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import SizesSelector from '~source/ui/components/organisms/product-detail-components/sizes-selector/sizes-selector';
import Grid from '~source/ui/components/templates/grid/grid';
import { calculateGridDetails } from '~source/ui/utils/math/calculate-grid-details/calculate-grid-details';
import $ from './product-recommendations-item.module.scss';

const SizesSkeleton = () => (
  <div className={$.row}>
    {Array.from({ length: 3 }, (_, i) => (
      <SkeletonBox key={i} height={37} width="32%" />
    ))}
  </div>
);

type Props = PropsWithChildren<{
  isProductPending: boolean;
  onSizeSelection: (size: number) => void;
  sizeGroups:
    | {
        name: string;
        sizes: (SizeModel | SizeModelConfigurable)[];
      }[]
    | null;
  selectSizeId: (
    sizeGroup: (SizeModel | SizeModelConfigurable)[],
  ) => number | null;
  error: string | null;
}>;

export const ProductRecommendationWidget = ({
  children,
  isProductPending,
  onSizeSelection,
  sizeGroups,
  selectSizeId,
  error,
}: Props) => {
  const { rowsNumber } = sizeGroups
    ? calculateGridDetails(sizeGroups[0].sizes, true)
    : { rowsNumber: 0 };

  return (
    <section className={$.widgetWrap}>
      <div className={$.widget}>
        <div className={$.sizesWrap}>
          {isProductPending ? (
            <SizesSkeleton />
          ) : (
            sizeGroups?.map(({ name, sizes: sizeGroup }) => (
              <SizesSelector
                key={name}
                sizes={sizeGroup}
                selected={selectSizeId(sizeGroup)}
                onChange={onSizeSelection}
                isCompact
              />
            ))
          )}
        </div>
        <Grid
          className={$.grid}
          style={{
            gridTemplateRows: `repeat(${Math.max(rowsNumber, 2)}, 1fr)`,
          }}
        >
          <GridItem xs={1}>{children}</GridItem>
        </Grid>
      </div>

      {error && (
        <div className={$.error}>
          <ErrorMessage message={error} type="error" />
        </div>
      )}
    </section>
  );
};
