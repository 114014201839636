import cx from 'classnames';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import { BannerImage } from '~source/core/models/components/atoms/banner-image';
import getBannerImageClassName from '~source/ui/utils/getters/get-banner-image-classname';
import $ from './responsive-image.module.scss';

interface Props {
  image: BannerImage;
  imageLocation?: 'left' | 'right' | 'full';
  sizes: string;
  style?: React.CSSProperties;
  className?: string;
}

const ResponsiveImage: FunctionComponent<Props> = ({
  image,
  imageLocation,
  sizes,
  style,
  className,
}) => {
  const { image: img, device } = image;
  let imageStyles: string;
  if (imageLocation === 'left') imageStyles = $.container;
  else if (imageLocation === 'right')
    imageStyles = cx($.container, $.isReversed);
  else if (imageLocation === 'full') imageStyles = $.isFull;
  else imageStyles = $.isFullHeight;

  if (!img) return null;

  return (
    <div
      className={cx(
        $.imageWrap,
        imageStyles,
        $[getBannerImageClassName(device)],
        className,
      )}
      style={style}
    >
      <Image
        className={$.image}
        src={img.url}
        alt={img.alt}
        // NOTE: This is the biggest version of a banner
        //   --: Making it smaller then 1240 will make the image not wider then selected width
        //   --: At 800 it looks like there might be missing a component in the carousel
        //   --: While in reallity the image is not in full width (100%)
        priority
        fill
        sizes={sizes}
      />
    </div>
  );
};

export default ResponsiveImage;
