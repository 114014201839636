import React, { useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { CategoriesSlider as SliderProps } from '~source/core/models/components/organisms/categories-slider';
import Container from '~source/ui/components/containers/container/container';
import CategoryBanner from '~source/ui/components/molecules/category-banner/category-banner';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';
import $ from './categories-slider.module.scss';

const MOBILE_BREAKPOINTS = {
  0: {
    slidesPerView: 1.5,
  },
  576: {
    slidesPerView: 2.2,
  },
  768: {
    slidesPerView: 2.75,
  },
};

const DEFAULT_BREAKPOINTS = {
  ...MOBILE_BREAKPOINTS,
  1024: {
    slidesPerView: 3,
  },
};

const MORE_BANNERS_BREAKPOINTS = {
  ...MOBILE_BREAKPOINTS,
  1024: {
    slidesPerView: 3.2,
  },
  1195: {
    slidesPerView: 3.5,
  },
};

const CategoriesSlider: React.FC<SliderProps> = ({ banners }) => {
  const { isTabletLandscape } = useBreakpoints();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const spaceBetween = isTabletLandscape ? 27 : 14;

  // Default amount of banners is equal to 3
  const defaultBannersLength = 3;
  const isMoreBanners = banners.length > defaultBannersLength;
  const breakpoints = isMoreBanners
    ? MORE_BANNERS_BREAKPOINTS
    : DEFAULT_BREAKPOINTS;

  return (
    <Container className={$.container}>
      <SwiperCarousel
        ref={containerRef}
        spaceBetween={spaceBetween}
        slidesPerView={3}
        isLoop={false}
        nested
        isScrollable
        isPagination={false}
        isPaginationVisible={isMoreBanners}
        breakpoints={breakpoints}
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.id}>
            <CategoryBanner key={banner.id} className={$.banner} {...banner} />
          </SwiperSlide>
        ))}
      </SwiperCarousel>
    </Container>
  );
};

export default CategoriesSlider;
