import React from 'react';
import { Button as ButtonModel } from '~source/core/models/components/atoms/button';
import Button from '~source/ui/components/atoms/buttons/button/button';
import $ from './button-block.module.scss';

interface Props {
  buttons: ButtonModel[];
}

const ButtonBlock: React.FC<Props> = ({ buttons }) => (
  <div className={$.container}>
    {buttons.map((item, index) => (
      <Button
        label={item.link.name}
        link={item.link.url}
        type="link"
        variant="red"
        key={index}
      />
    ))}
  </div>
);

export default ButtonBlock;
