import cx from 'classnames';
import * as React from 'react';
import { Banner as BannerModel } from '~source/core/models/components/molecules/banner';
import Button from '~source/ui/components/atoms/buttons/button/button';
import ResponsiveImage from '~source/ui/components/atoms/responsive-image/responsive-image';
import { getTitleClassName } from '~source/ui/utils/get-title-classname';
import $ from './banner.module.scss';

const Banner: React.FunctionComponent<BannerModel> = ({
  title,
  text,
  images,
  isDark,
  links,
  titleAtTheTop,
  titleFont,
  titleSize,
  showText,
  isDragable = false,
}) => {
  const titleClassnames = cx(
    getTitleClassName(titleSize, $),
    titleFont === 'lexia' && $.titleLexia,
    !isDark && $.titleIsDark,
    $.title,
  );
  const textClassnames = cx(
    $.text,
    !isDark && $.textIsDark,
    showText === 'tablet and up' && $.textTabletDesktop,
  );
  const buttonClassnames = cx(
    $.button,
    !isDragable && links.length === 1 && $.buttonSpanClickableArea,
  );

  const titleId = React.useId();

  return (
    <article
      aria-labelledby={title ? titleId : undefined}
      // NOTE: Added this to show that some images are missing from CMS
      style={{
        backgroundImage:
          'url("/shop/images/backgrounds/visuals-patterns-red.webp")',
      }}
      className={cx($.container, !titleAtTheTop && $.containerTitleTop)}
    >
      <div className={$.imagesContainer}>
        {images.length > 0 &&
          images.map((image) => (
            <ResponsiveImage
              key={`${image.image?.id}-${image.device}`}
              image={image}
              sizes="(min-width: 90em) 1240px, 100vw"
            />
          ))}
      </div>
      <div
        className={cx(
          $.content,
          isDragable && $.contentNoSelect,
          !titleAtTheTop && $.contentBottomShadow,
        )}
      >
        <div className={cx(!titleAtTheTop && $.contentBottom)}>
          {title && (
            <h1 className={titleClassnames} id={titleId}>
              {title}
            </h1>
          )}
          {showText && <p className={textClassnames}>{text}</p>}
        </div>
        <div>
          {links.map((link, index) => (
            <Button
              className={buttonClassnames}
              label={link.link.name}
              link={link.link.url}
              variant={index === 0 && link.color ? link.color : undefined}
              type="link"
              key={link.link.id}
            />
          ))}
        </div>
      </div>
    </article>
  );
};

export default Banner;
