import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import * as React from 'react';
import { SwiperSlide } from 'swiper/react';
import { BASE_PATH } from '~source/constants/paths';
import type { ProductRecommendation } from '~source/core/models/components/molecules/product-recommendation';
import type { DYChoiceRecommendationDto } from '~source/core/services/dynamic-yield/dy-choose-variations';
import Container from '~source/ui/components/containers/container/container';
import ProductRecommendationsItem from '~source/ui/components/molecules/product-recommendations-item/product-recommendations-item';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';

type Props = {
  dySelector: string;
};

const transformProductRecommendation = (
  data: DYChoiceRecommendationDto,
): ProductRecommendation[] => {
  const { slots } = data;
  return slots.map(
    (slot): ProductRecommendation => ({
      name: slot.productData.name,
      path: slot.productData.url,
      primaryImageUrl: slot.productData.image_url,
      productId: Number(slot.productData.id),
    }),
  );
};

const ProductRecommendationsSlider = ({ dySelector }: Props) => {
  const router = useRouter();
  const { isLaptop } = useBreakpoints();
  const spaceBetween = isLaptop ? 20 : 10;

  const [products, setProducts] = React.useState<
    ProductRecommendation[] | null
  >(null);

  React.useEffect(() => {
    fetch(
      `${BASE_PATH}/api/dy/recommendations?location=${router.pathname}&dySelector=${dySelector}`,
    )
      .then(async (response) => {
        if (response.ok) {
          const data = (await response.json()) as DYChoiceRecommendationDto;
          setProducts(transformProductRecommendation(data));
        } else {
          throw Error(response.statusText);
        }
      })
      .catch(Sentry.captureException);
  }, [dySelector, router.pathname]);

  if (!products) return null;

  return (
    <Container>
      <SwiperCarousel
        height="auto"
        nested
        useBaseStyle
        slidesPerView={5}
        spaceBetween={spaceBetween}
        isScrollable
        isPagination={false}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.productId}>
            <ProductRecommendationsItem
              key={product.productId}
              product={product}
            />
          </SwiperSlide>
        ))}
      </SwiperCarousel>
    </Container>
  );
};

export default ProductRecommendationsSlider;
