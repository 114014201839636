import Image from 'next/image';
import React from 'react';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import type { ProductRecommendation } from '~source/core/models/components/molecules/product-recommendation';
import Button from '~source/ui/components/atoms/buttons/button/button';
import ErrorMessage from '~source/ui/components/atoms/error-message/error-message';
import PriceDisplay from '~source/ui/components/atoms/price-display/price-display';
import SkeletonBox from '~source/ui/components/atoms/skeleton-box/skeleton-box';
import useSizes from '~source/ui/components/organisms/product-detail-components/hero-block/hooks/useSizes';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import useAddProductToOrder from '~source/ui/hooks/product/useAddProductToOrder';
import useProductDetails from '~source/ui/hooks/product/useProductDetails/useProductDetails';
import { isStock } from '~source/ui/utils/checks/is-product-type';
import { ProductRecommendationAddToCart } from './product-recommendations-add-to-cart';
import $ from './product-recommendations-item.module.scss';
import { ProductRecommendationWidget } from './product-recommendations-widget';

type Props = {
  product: ProductRecommendation;
};

const ProductRecommendationsItem = ({ product }: Props) => {
  const { path, primaryImageUrl, name, productId } = product;
  const locale = useLocale();
  const t = useTranslate();
  const { value, status } = useProductDetails(productId, locale);
  const { price, productTypes, sizes } = value || {};

  const [isOpen, setIsOpen] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  const {
    selectedSizeIds,
    selectedSizes,
    sizeGroups,
    handleSizeSelection,
    reset: resetSize,
    error: sizesError,
    validate: validateSizes,
  } = useSizes(sizes || []);

  const {
    addToBasket,
    status: statusAddToBasket,
    error: addError,
  } = useAddProductToOrder({
    productTypes: productTypes || [],
    productId,
    sizes: sizes || [],
    selectedSizeIds,
  });

  const isStockProduct = productTypes && isStock(productTypes);
  const isProductPending = status === 'pending';

  const error = React.useMemo(() => {
    if (hasSubmitted) {
      return isStockProduct ? addError : sizesError || addError;
    }
    return '';
  }, [hasSubmitted, sizesError, addError, isStockProduct]);

  const selectSizeId = React.useCallback(
    (sizeGroup: (SizeModel | SizeModelConfigurable)[]) =>
      selectedSizeIds.find((selectedSizeId) =>
        sizeGroup.some((size) => size.id === selectedSizeId),
      ) ?? null,
    [selectedSizeIds],
  );

  const handleAddToBasket = async () => {
    setHasSubmitted(true);

    if (!isStockProduct) {
      const areSizesValid = validateSizes();
      if (!areSizesValid || !selectedSizes.length) return;
    }

    await addToBasket();

    if (!isStockProduct) {
      resetSize();
    }
  };

  return (
    <article className={$.wrapper} onMouseLeave={() => setIsOpen(false)}>
      <div className={$.imageWrap}>
        <Image
          src={primaryImageUrl}
          alt=""
          className={$.image}
          width={220}
          height={254}
        />

        <div className={$.buttonsGroup}>
          <div className={$.row}>
            {value &&
              (isStockProduct ? (
                <ProductRecommendationAddToCart
                  className={$.addStockButton}
                  isProductPending={isProductPending}
                  status={statusAddToBasket}
                  onAdd={handleAddToBasket}
                />
              ) : (
                <details className={$.details} open={isOpen}>
                  <summary className={$.summary}>
                    <Button
                      className={$.sizeButton}
                      type="button"
                      variant="red"
                      onClick={() => setIsOpen(true)}
                    >
                      {t('SELECT_SIZE')}
                    </Button>
                  </summary>
                  <ProductRecommendationWidget
                    isProductPending={isProductPending}
                    onSizeSelection={handleSizeSelection}
                    sizeGroups={sizeGroups}
                    selectSizeId={selectSizeId}
                    error={error}
                  >
                    <ProductRecommendationAddToCart
                      className={$.addSizeButton}
                      isProductPending={isProductPending}
                      status={statusAddToBasket}
                      onAdd={handleAddToBasket}
                      onSizeSelection={handleSizeSelection}
                    />
                  </ProductRecommendationWidget>
                </details>
              ))}
            <Button
              className={$.link}
              type="link"
              variant="outline-light-grey"
              link={path}
            >
              {t('MORE_INFO')}
            </Button>
          </div>

          {error && (
            <div className={$.addError}>
              <ErrorMessage message={error} type="error" />
            </div>
          )}
        </div>
      </div>

      <div className={$.info}>
        {name && <h3 className={$.title}>{name}</h3>}
        {isProductPending && <SkeletonBox height={25} width={60} />}
        {price && (
          <div className={$.price}>
            {price.sale !== null && price.original && (
              <div className={$.priceOriginal}>
                <PriceDisplay price={price.original} discounted size="small" />
              </div>
            )}
            {price.original && (
              <PriceDisplay
                price={price.sale !== null ? price.sale : price.original}
                isSalePrice={price.sale !== null}
                size="normal"
              />
            )}
          </div>
        )}
      </div>
    </article>
  );
};

export default ProductRecommendationsItem;
