import { BannerImage } from '~source/core/models/components/atoms/banner-image';
import InnerHtml from '~source/ui/components/atoms/inner-html/inner-html';
import ResponsiveImage from '~source/ui/components/atoms/responsive-image/responsive-image';
import Container from '~source/ui/components/containers/container/container';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './ProductHeader.module.scss';

type Props = {
  title: string | null;
  content: string | null;
  imageLocation: 'left' | 'right' | 'full';
  images: BannerImage[];
  isFirstModule: boolean;
};

export default function ProductHeader({
  title,
  content,
  imageLocation,
  images,
  isFirstModule,
}: Props) {
  return (
    <Container
      className={cx($.container, imageLocation === 'right' && $.isReversed)}
    >
      <div
        className={cx(
          $.wrapper,
          imageLocation === 'full' && $.wrapperFull,
          isFirstModule && $.wrapperMarginTop,
        )}
      >
        {images.length > 0 &&
          images.map((image) => (
            <ResponsiveImage
              key={`${image?.image?.id}-${image.device}`}
              image={image}
              imageLocation={imageLocation}
              sizes="(min-width: 77rem) 77rem, 100vw"
            />
          ))}
        <div
          className={cx($.content, imageLocation === 'full' && $.contentFull)}
        >
          <h1 className={$.title}>{title}</h1>
          {content && (
            <InnerHtml
              container={<div className={$.contentDescription} />}
              html={content}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
