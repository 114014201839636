import React from 'react';
import type { HeaderBanner } from '~source/core/models/components/molecules/header-banner';
import Button from '~source/ui/components/atoms/buttons/button/button';
import { getTitleClassName } from '~source/ui/utils/get-title-classname';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './banners-slider.module.scss';

type Props = {
  banner: HeaderBanner;
};

const Content: React.FC<Props> = ({ banner }) => {
  const {
    contentAlignment,
    links,
    title,
    titleSize,
    titleFont,
    titleFontColor,
    text,
  } = banner;

  const titleClassName = cx(
    $.title,
    titleFontColor === 'Black' && $.titleDark,
    titleFont === 'lexia' && $.titleLexia,
    getTitleClassName(titleSize, $),
  );

  const textClassName = cx(
    $.text,
    titleFontColor === 'Black' && $.textDark,
    titleFont === 'lexia' && $.textLexia,
  );

  return (
    <div className={$.contentWrapper}>
      <div
        className={cx(
          $.content,
          contentAlignment === 'Right' && $.isAlignedToRight,
        )}
      >
        {title && <h2 className={titleClassName}>{title}</h2>}

        {text && <p className={textClassName}>{text}</p>}

        {links.map(({ link, color }) => (
          <Button
            key={link.id}
            className={$.button}
            type="link"
            label={link.name}
            variant={color}
            link={link.url}
          >
            {link.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Content;
