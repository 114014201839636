import React from 'react';
import $ from './code-block.module.scss';

interface Props {
  code?: string;
  anchor?: string;
}

const CodeBlock: React.FC<Props> = ({ code, anchor }) => (
  <div id={anchor} className={$.container}>
    <code>{code}</code>
  </div>
);

export default CodeBlock;
