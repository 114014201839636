import Image from 'next/image';
import * as React from 'react';
import { FilterBarPageModule } from '~source/core/models/components/molecules/filter-bar';
import {
  SetFilterFunction,
  SelectedFilterOptions,
  SetSortDirectionFunction,
  SortDirection,
} from '~source/core/models/components/molecules/filters';
import uuid from '~source/core/utils/uuid';
import MultiSelect from '~source/ui/components/atoms/form/multi-select/multi-select';
import Container from '~source/ui/components/containers/container/container';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './filter-bar.module.scss';

type Props = FilterBarPageModule & {
  setFilterOption: SetFilterFunction;
  selectedFilterOptions: SelectedFilterOptions;
  setSortDirection: SetSortDirectionFunction;
  selectedSortDirection: SortDirection;
};

export default function FilterBar({
  filters,
  setFilterOption,
  selectedFilterOptions,
  setSortDirection,
  selectedSortDirection,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const t = useTranslate();

  function onFilterClick() {
    setOpen(!open);
  }

  return (
    <Container
      className={cx($.wrapper, open && $.isOpen)}
      aria-describedby="filter-bar-description"
    >
      <div className={$.titleContainer}>
        <h2 id="filter-bar-description">
          {t('PRODUCT_LIST_FILTERSECTION_TITLE')}
        </h2>
        <button
          type="button"
          onClick={onFilterClick}
          aria-label={t('PRODUCT_LIST_FILTERSECTION_TITLE') as string}
        >
          <Image
            alt=""
            src={
              open
                ? '/shop/images/icons/ic-clear.svg'
                : '/shop/images/icons/ic-filter.svg'
            }
            width={open ? 24 : 34}
            height={open ? 24 : 18}
            objectFit="contain"
          />
        </button>
      </div>
      <div className={cx($.filterContainer, open && $.isOpen)}>
        <div className={cx($.filterBar)}>
          {filters.map((filter) => (
            <div key={filter.id} className={$.filterBarItem}>
              <MultiSelect
                id={filter.id}
                disableIconMobile
                label={filter.label}
                values={selectedFilterOptions[filter.property] || []}
                onChange={(values) => setFilterOption(filter.property, values)}
              >
                {filter.options.map((option) => (
                  <MultiSelect.Option
                    key={option.id}
                    id={option.id}
                    label={option.label}
                    value={option.property}
                  />
                ))}
              </MultiSelect>
            </div>
          ))}
        </div>
        <div key={uuid()} className={cx($.filterBarItem, $.sortBar)}>
          <MultiSelect
            id="filter-sorting"
            label="Sorteer op"
            bold={false}
            values={
              selectedSortDirection === 'unsorted'
                ? []
                : [selectedSortDirection]
            }
            onChange={(values) => {
              const value = values[values.length - 1] || 'unsorted';
              setSortDirection(value as SortDirection);
            }}
          >
            <MultiSelect.Option
              key={uuid()}
              id={uuid()}
              label="Alfabetisch"
              value="alphabet"
            />
            <MultiSelect.Option
              key={uuid()}
              id={uuid()}
              label="Prijs oplopend"
              value="priceup"
            />
            <MultiSelect.Option
              key={uuid()}
              id={uuid()}
              label="Prijs aflopend"
              value="pricedown"
            />
          </MultiSelect>
        </div>
      </div>
    </Container>
  );
}
