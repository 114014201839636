import * as React from 'react';

type Props = {
  title?: string;
};
type State = {
  hasError: boolean;
  errorMessage: string | null;
};

class ErrorCatcher extends React.Component<
  React.PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
    };
  }

  componentDidCatch(error: any) {
    const errorMessage =
      typeof error === 'string' ? error : error?.message || null;
    this.setState({
      hasError: true,
      errorMessage,
    });
  }

  reset = () => this.setState({ hasError: false, errorMessage: null });

  render() {
    const { title, children } = this.props;
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      // render nothing for production, otherwise render error block.
      if (process.env.NODE_ENV === 'production') {
        return null;
      }
      return (
        <div
          style={{
            backgroundColor: 'tomato',
            color: 'white',
            borderRadius: '0.3em',
            padding: '1em',
          }}
        >
          {title && <h2>{title}</h2>}
          <p>
            Something went wrong,{' '}
            <button
              type="button"
              onClick={this.reset}
              style={{ color: 'currentcolor' }}
            >
              reset?
            </button>
          </p>
          {errorMessage && <pre>{errorMessage}</pre>}
        </div>
      );
    }

    return children;
  }
}

function withErrorCatcher<P extends React.JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P>,
) {
  return function WithErrorCatcher(props: P) {
    return (
      <ErrorCatcher>
        <WrappedComponent {...props} />
      </ErrorCatcher>
    );
  };
}

export { ErrorCatcher, withErrorCatcher };
export default ErrorCatcher;
