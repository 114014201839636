import InnerHtml from '~source/ui/components/atoms/inner-html/inner-html';
import Container from '~source/ui/components/containers/container/container';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './text-block.module.scss';

type Props = {
  title: string | null;
  content: string | null;
};

export default function TextBlock({ title, content }: Props) {
  return (
    <Container className={$.container}>
      <div className={cx($.content)}>
        {title && <h2 className={$.title}>{title}</h2>}
        {content && (
          <InnerHtml
            container={<div className={$.contentDescription} />}
            html={content}
          />
        )}
      </div>
    </Container>
  );
}
