import * as React from 'react';
import useOnClickOutside from '~source/ui/hooks/ui/useOnClickOutside/useOnClickOutside';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './multi-select.module.scss';

type Props = React.PropsWithChildren<{
  id: string;
  label: string;
  values: string[];
  onChange: (values: string[]) => void;
  disableIconMobile?: boolean;
  bold?: boolean;
}>;

type OptionProps = {
  id: string;
  label: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MultiSelectOption(props: OptionProps) {
  return null;
}

function formatLabel(
  defaultLabel: string,
  values: string[],
  options: OptionProps[],
): string {
  const selectedValue = values[0];
  if (selectedValue) {
    const label = options.find(
      (option) => option.value === selectedValue,
    )?.label;
    if (label) {
      if (values.length > 1) {
        return `${label} (+)`;
      }
      return label;
    }
  }
  return defaultLabel;
}

function MultiSelect({
  id,
  label,
  values,
  onChange,
  children,
  disableIconMobile,
  bold = true,
}: Props) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [keyPressed, setKeyPressed] = React.useState<string>('');
  const [active, setActive] = React.useState(false);
  const close = React.useCallback(() => {
    setActive(false);
  }, []);
  useOnClickOutside(containerRef, close);

  const options = React.Children.toArray(children).reduce<OptionProps[]>(
    (acc, child) => {
      if (React.isValidElement(child)) {
        acc.push(child.props);
      }
      return acc;
    },
    [],
  );

  const refsById = React.useMemo(() => {
    const refs: React.RefObject<HTMLButtonElement>[] = [];
    // use createRef here because refs are not available with useRef when MultiSelectOption's are hidden for the user
    options.forEach((option: OptionProps, index: number) => {
      refs[index] = React.createRef();
    });
    return refs;
  }, [options]);

  const handleOptionChange = (selectedValue: string) => {
    if (values.includes(selectedValue)) {
      onChange(values.filter((value) => value !== selectedValue));
    } else {
      onChange(values.concat(selectedValue));
    }
  };

  const focusOption = (index: number) => {
    refsById[index].current?.focus();
  };

  const findOptionIndex = (node: EventTarget) =>
    refsById.findIndex((ref) => ref.current === node);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    setKeyPressed(event.key);

    switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault();

        const index = findOptionIndex(event.target) - 1;
        index < 0 ? buttonRef.current?.focus() : focusOption(index);
        break;
      }

      case 'ArrowDown': {
        event.preventDefault();
        !active && setActive(true);

        const index = Math.min(
          findOptionIndex(event.target) + 1,
          refsById.length - 1,
        );
        focusOption(index);
        break;
      }
      case 'Escape':
        event.preventDefault();
        setActive(false);
        buttonRef.current?.focus();
        break;

      case 'Home':
        event.preventDefault();
        active && focusOption(0);
        break;

      case 'End':
        event.preventDefault();
        active && focusOption(refsById.length - 1);
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (active) {
      if (keyPressed === 'ArrowDown') {
        focusOption(0);
      }
    }
  }, [active]);

  const fakeOptionsRender = options.map((option) => (
    <div
      key={option.id}
      className={cx($.option, values.includes(option.value) && $.isSelected)}
    >
      <span className={$.optionLabel}>{option.label}</span>
      <span className={$.optionIcon} />
    </div>
  ));

  const optionsRender = options.map((option, index) => (
    <button
      type="button"
      key={option.id}
      className={$.option}
      aria-pressed={values.includes(option.value)}
      ref={refsById[index]}
      onClick={() => handleOptionChange(option.value)}
      onKeyDown={handleKeyDown}
    >
      <span className={$.optionLabel}>{option.label}</span>
      <span className={$.optionIcon} />
    </button>
  ));

  return (
    <div
      ref={containerRef}
      className={cx(
        $.wrapper,
        active && $.isActive,
        values.length > 0 && $.hasSelected,
      )}
    >
      <button
        className={cx($.button, bold && $.isBold)}
        type="button"
        ref={buttonRef}
        aria-expanded={active}
        aria-controls={`${id}-options`}
        onClick={() => setActive(!active)}
        onKeyDown={handleKeyDown}
        aria-label={label}
      >
        <span className={$.buttonLabel}>
          {formatLabel(label, values, options)}
        </span>
        <span
          className={cx($.buttonIcon, disableIconMobile && $.disableIcon)}
        />
      </button>
      {/* This only exist so the wrapper has the correct width */}
      <div aria-hidden="true" className={$.wrapperPush}>
        {fakeOptionsRender}
      </div>
      {active && (
        <div id={`${id}-options`} className={$.options}>
          {optionsRender}
        </div>
      )}
    </div>
  );
}

MultiSelect.Option = MultiSelectOption;

export default MultiSelect;
