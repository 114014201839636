import React, { useEffect, useState, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { BREAK_PHONE_VALUE, BREAK_TABLET_VALUE } from '~source/constants';
import {
  Banner as BannerType,
  TitleSize,
} from '~source/core/models/components/molecules/banner';
import { CarouselItemSize } from '~source/core/models/components/molecules/banners-carousel';
import Container from '~source/ui/components/containers/container/container';
import Banner from '~source/ui/components/molecules/banner/banner';
import SwiperCarousel from '~source/ui/components/molecules/swiper/swiper-carousel';
import debounce from '~source/ui/utils/debounce';
import $ from './banners-swiper.module.scss';

interface Banners extends BannerType {
  tileSize: CarouselItemSize;
  carouselWidth?: number;
}

interface Props {
  banners: Banners[];
}

const getTitleSize = (
  tileSize: number,
  bigTile: number,
): TitleSize | undefined => {
  if (tileSize >= bigTile) return 'mega-carousel';
  if (bigTile === 13) return 'small';
  if (tileSize > 4) return 'medium';

  return undefined;
};

const BannersSwiper: React.FC<Props> = ({ banners }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const baseBigTile = 11;
  const [bigTile, setBigTile] = useState(baseBigTile);
  const height = 480;

  useEffect(() => {
    const handler = debounce(() => {
      if (document.body.clientWidth <= BREAK_TABLET_VALUE) {
        setBigTile(12);
      } else {
        setBigTile(baseBigTile);
      }

      if (document.body.clientWidth <= BREAK_PHONE_VALUE) {
        setBigTile(13);
      }
    }, 300);

    handler();

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  if (banners.length === 1) {
    const {
      id,
      title,
      text,
      links,
      tileSize,
      images,
      isDark,
      price,
      titleFont,
    } = banners[0];

    return (
      <Container className={$.containerSingleBanner}>
        <Banner
          id={id}
          key={id}
          title={title}
          text={text}
          images={images}
          isDark={isDark}
          links={links}
          moduleType="banner"
          price={price}
          titleAtTheTop
          titleFont={titleFont}
          titleSize={getTitleSize(tileSize, bigTile)}
          showText="all devices"
        />
      </Container>
    );
  }

  return (
    <SwiperCarousel
      ref={containerRef}
      height={height}
      className={$.container}
      isLoop
    >
      {banners.map(
        ({
          id,
          title,
          text,
          links,
          tileSize,
          images,
          isDark,
          price,
          titleFont,
        }) => (
          <SwiperSlide
            style={{ height }}
            key={id}
            className={$[`width--${tileSize}`]}
          >
            <Banner
              id={id}
              key={id}
              title={title}
              text={text}
              images={images}
              isDark={isDark}
              links={links}
              moduleType="banner"
              price={price}
              titleAtTheTop={tileSize >= bigTile}
              titleFont={titleFont}
              titleSize={getTitleSize(tileSize, bigTile)}
              showText={tileSize >= bigTile ? 'all devices' : undefined}
            />
          </SwiperSlide>
        ),
      )}
    </SwiperCarousel>
  );
};

export default BannersSwiper;
