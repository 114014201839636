import React from 'react';
import { WelcomeText as WelcomeTextProps } from '~source/core/models/components/molecules/welcome-text';
import Button from '~source/ui/components/atoms/buttons/button/button';
import Container from '~source/ui/components/containers/container/container';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import Grid from '~source/ui/components/templates/grid/grid';
import $ from './welcome-text.module.scss';

const WelcomeText: React.FC<WelcomeTextProps> = ({ text, link, enabled }) => {
  if (!enabled || text === undefined) {
    return null;
  }

  return (
    <Container>
      <Grid>
        <GridItem xs={12} m={8}>
          <div className={$.container}>
            <h1 className={$.title}>{text}</h1>
            <Button
              link={link.url}
              label={link.name}
              isExternal={link.target === '_blank'}
              variant="red"
            />
          </div>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default WelcomeText;
