import dynamic from 'next/dynamic';
import SkeletonBox from '~source/ui/components/atoms/skeleton-box/skeleton-box';
import Container from '~source/ui/components/containers/container/container';
import $ from './video-player.module.scss';

const Skeleton = () => (
  <Container>
    <SkeletonBox className={$.skeleton} />;
  </Container>
);

const DynamicVideoPlayer = dynamic(() => import('./video-player'), {
  ssr: false,
  loading: Skeleton,
});

export default DynamicVideoPlayer;
