import * as React from 'react';
import { CategoryBannerType as CategoryBannerProps } from '~source/core/models/components/organisms/categories-slider';
import Button from '~source/ui/components/atoms/buttons/button/button';
import ResponsiveImage from '~source/ui/components/atoms/responsive-image/responsive-image';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './category-banner.module.scss';

type Props = CategoryBannerProps & {
  className?: string;
};

const CategoryBanner: React.FC<Props> = ({ images, links, className }) => {
  return (
    <article className={cx($.wrapper, className)}>
      {images.map((image) => (
        <ResponsiveImage
          key={`${image.image?.id}-${image.device}`}
          image={image}
          sizes="
            (max-width: 768px) 75vw,
            (max-width: 1024px) 50vw,
            33vw"
        />
      ))}

      <div className={$.content}>
        {links.map(({ link, color }) => (
          <Button
            key={link.id}
            variant={color}
            link={link.url}
            className={$.button}
            type="link"
          >
            {link.name}
          </Button>
        ))}
      </div>
    </article>
  );
};

export default CategoryBanner;
